<template>
    <CardComponent
        :title="title"
        :icon="icon"
        width=".98rem"
        class="table-in-card"
        :style="{
            'grid-row-end': 'span' + ' ' + rowSpan,
            'grid-column-end': 'span' + ' ' + colSpan,
        }"
    >
        <!-- 选择区域 -->
        <template v-slot:cardOptions>
            <slot name="cashOption"></slot>
        </template>
        <!-- 操作区域 -->
        <template v-slot:cardHeader v-if="handleIcon">
            <i
                element-loading-spinner="el-icon-loading"
                :class="handleIcon"
                @click="showDialog(title)"
            ></i>
        </template>
        <!-- 统计区域 -->
        <slot name="sumArea"></slot>
        <!-- 表格区域 -->
        <div class="table-in-card-main_body">
            <el-table height="2.6rem" :data="datas" v-if="datas&&datas.length > 0" ref="table">
                <el-table-column
                    v-for="column in columns"
                    :key="column.label"
                    :prop="column.prop"
                    :label="column.label"
                    :align="column.align ? column.align : 'center'"
                    :show-overflow-tooltip="
                        column.showOverflowTooltip
                            ? column.showOverflowTooltip
                            : true
                    "
                    :sortable="column.sortable ? column.sortable : false"
                >
                    <template slot-scope="scope">
                        <el-link
                            v-if="column.link"
                            @click="showEdit(scope.row)"
                            type="primary"
                        >
                            {{ scope.row[column.prop] }}
                        </el-link>

                        <span v-else-if="column.label==='金额'">
                            ￥{{ moneyFilter(scope.row[column.prop],true,7) }}
                        </span>
                        <span v-else :class="{ markRed: scope.row.delay }">
                            {{ scope.row[column.prop] }}
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <Empty v-else />
        </div>
        <cashRest :parenterId="parenterId" v-on="$listeners" ref="cashRest" />
        <addCheckReceive
            v-bind="$attrs"
            v-on="$listeners"
            ref="addCheckReceive"
        />
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import cashRest from '../components/restCashDialog'
import addCheckReceive from '../components/addCheckReceive'
import _ from 'lodash'
import contant from './contant'
import financeSvc from 'services/finance'
import { moneyFilter } from 'utils/index'
import moment from 'moment'
import Empty from '@/components/empty'
export default {
    name: '',
    components: { CardComponent, cashRest, addCheckReceive,Empty },
    props: {
        title: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            default: 'iconfont icon-caiwuyunying',
        },
        handleIcon: {
            type: String,
            default: null,
        },
        rowSpan: {
            type: Number,
            default: 2,
        },
        colSpan: {
            type: Number,
            default: 1,
        },
        columns: {
            type: Array,
            default: null,
        },
        datas: {
            type: Array,
            default: null,
        },
        parenterId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {}
    },
    created() {},
    methods: {
        moment,
        moneyFilter,
        async showDialog(title) {
            console.log(contant.financeDesktopText)
            console.log(title)
            console.log(this.parenterId)
            if (title === contant.financeDesktopText.CASH_REST) {
                try {
                    // await financeSvc.getCashByDate('2021-06-01')
                    this.$set(this.$refs.cashRest, 'dateTime', new Date())
                    this.$refs.cashRest.visible = true
                } catch (error) {
                    console.log(error)
                }
            }
            if (title === contant.financeDesktopText.CHECK_RECEIVE) {
                try {
                    this.$refs.addCheckReceive.isEdit = false
                    this.$refs.addCheckReceive.visible = true
                } catch (error) {
                    console.log(error)
                }
            }
        },
        tableLayout(){
            this.$nextTick(()=>{
                this.$refs.table.doLayout()
            })
        },
        async showEdit(item) {
            console.log(item)
            const { data = {} } = await financeSvc.queryCheckReceiveBillById(
                item.id
            )
            let _ref = this.$refs.addCheckReceive
            data.dateRange = [data.startTime, data.endTime]
            _ref.isEdit = true
            _ref.form = _.cloneDeep(data)
            _ref.visible = true
        },
    },
}
</script>
<style lang="scss" scoped>
.table-in-card {
    ::v-deep .main {
        // width: 3.6rem;
        box-sizing: border-box;
        padding: 0;
        .com-card-header {
            padding: 0.2rem;
        }
        .com-card-header .left {
            display: flex;
        }
        .com-card-header .right {
            i {
                font-size: 0.28rem;
            }
        }
        .com-card-header .title {
            font-size: 0.24rem;
        }
    }
    ::v-deep &-main__top {
        display: flex;
        padding: 0 0.2rem;
        > div {
            flex: 1;
        }
        > div + div {
            margin-left: 0.15rem;
        }
        p {
            font-weight: 500;
        }
        p.title {
            font-size: 0.18rem;
            color: #909399;
            // line-height: 0.26rem;
        }
        p.value {
            span {
                font-size: 0.18rem;
            }
            display: flex;
            font-size: 0.24rem;
            color: #54636f;
            line-height: 0.44rem;
        }
        ::v-deep .date-picker {
            width: 1.6rem;
            cursor: pointer;
            .el-icon-date,
            input {
                font-size: 0.2rem;
            }
            .el-icon-date {
                line-height: 42px;
            }
            .el-input__inner {
                cursor: pointer;
                border: none;
                background: transparent;
                padding-left: 0.35rem;
                padding-right: 0;
                color: #606266;
            }
        }
    }
    &-main_body {
        padding: 0 0.1rem;
        overflow: auto;
        box-sizing: border-box;
    }
    ::v-deep .el-table {
        th,
        tr,
        td {
            padding: 0;
            line-height: 40px;
            height: 40px;
        }
        span.markRed {
            color: #df5a68;
        }
    }
}
</style>
